import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick } from 'vue';
import useTablePagination from '@/hooks/pagination';
import logisticsModal from './components/logisticsModal.vue';
import logisticsDetailModal from './components/logisticsDetailModal.vue';
import Cookies from "js-cookie";
import { selectBelongContract } from '@/api/officeApi';
import { useStore } from 'vuex';
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import responsibleConf from './components/responsibleConfForLogistics.vue';
import { selectLogisticsByPagination } from '@/api/logistics';
import { selectVerifierSetting, selectApprovalItem, setTemporaryTransferState, setTemporaryTransfer, selectApproachByModular, selectJobCascade, deleteVerifierSetting } from '@/api/material';
import moment from 'moment'; //表头

const columns = [{
  title: '主题',
  dataIndex: 'theme'
}, {
  title: '发起人',
  dataIndex: 'userName'
}, {
  title: '发起时间',
  dataIndex: 'createTime',
  sorter: (a, b) => {
    let aTimeString = a.createTime;
    let bTimeString = b.createTime;
    let aTime = new Date(aTimeString).getTime();
    let bTime = new Date(bTimeString).getTime();
    return aTime - bTime;
  }
}, {
  title: '计划发运时间',
  dataIndex: 'planTransportTime',
  sorter: (a, b) => {
    let aTimeString = a.planTransportTime;
    let bTimeString = b.planTransportTime;
    let aTime = new Date(aTimeString).getTime();
    let bTime = new Date(bTimeString).getTime();
    return aTime - bTime;
  }
}, {
  title: '归属合同',
  dataIndex: 'belongContract'
}, {
  title: '状态',
  dataIndex: 'state',
  slots: {
    customRender: 'state'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
const responsibleColumns = [{
  title: '名称',
  dataIndex: 'itemName'
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
const transferColumns = [{
  title: '原审批责任人',
  dataIndex: 'sourceName'
}, {
  title: '临时审批责任人',
  dataIndex: 'targetName'
}, {
  title: '转移期限',
  dataIndex: 'time'
}, {
  title: '状态',
  dataIndex: 'state',
  slots: {
    customRender: 'state'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    logisticsModal,
    logisticsDetailModal,
    QuestionCircleOutlined,
    responsibleConf
  },

  setup() {
    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);
    const formRef = ref();
    const formState = reactive({
      state: null,
      applicantName: null,
      belongContract: null,
      data: []
    }); //点击查询

    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    }; //表格


    const tableData = ref([]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const getTableData = async () => {
      const {
        data
      } = formState;
      const [startTime, endTime] = data.map((item, index) => {
        if (index) {
          return item.endOf('day').format('YYYY-MM-DD');
        }

        return item.startOf('day').format('YYYY-MM-DD');
      });
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        state: formState.state ? formState.state : null,
        applicantName: formState.applicantName ? formState.applicantName : null,
        belongContract: formState.belongContract ? formState.belongContract : null,
        startTime: startTime ? startTime : null,
        endTime: endTime ? endTime : null
      };
      loading.value = true;

      try {
        let res = await selectLogisticsByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          loading.value = false;
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch {
        loading.value = false;
      }
    }; //发起申请


    const applyVisible = ref(false);

    const handleApply = () => {
      applyVisible.value = true;
    }; //关闭申请弹窗


    const closeApplyVisible = val => {
      applyVisible.value = val.isClose;

      if (val.state == 2) {
        getTableData();
      }
    }; //详情


    const applyDetailVisible = ref(false);
    const applyDetailData = reactive({
      state: 1,
      // data:{}
      key: null,
      applyVisible: false
    }); //查看详情

    const toView = async (item, num) => {
      applyDetailData.state = num;
      applyDetailData.key = item.key;
      applyDetailData.applyVisible = true;
    }; //关闭详情页


    const closeApplyDetailVisible = val => {
      applyDetailData.applyVisible = val.isClose;

      if (val.state == 2) {
        getTableData();
      }
    };

    const updateDataValue = val => {
      applyDetailData.data = val;
    }; //归属合同查询


    const belongContractOptions = ref([]);

    const getBelongContract = async () => {
      let res = await selectBelongContract();

      if (res.code === 200) {
        belongContractOptions.value = [];
        if (res.data) res.data.forEach(item => {
          belongContractOptions.value.push({
            value: item.contractName,
            label: item.contractName
          });
        });
      } else {
        _message.error(res.message);
      }
    }; //责任人配置


    const respVisible = ref(false);
    const respData = reactive({
      // professionals:[],
      rests: [],
      finances: [],
      itemId: null,
      itemName: null
    });

    const handleConf = async () => {
      const param = {
        type: 13,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        const arr = [];
        const arr1 = [];
        projectByResponsible.value = [];
        transferData.value = [];
        if (res.data.approvalItems && res.data.approvalItems.length) res.data.approvalItems.forEach(item => {
          arr.push({
            key: item.id,
            ...item
          });
        });
        Object.assign(projectByResponsible.value, arr);
        if (res.data.approvalTransfers && res.data.approvalTransfers.length) res.data.approvalTransfers.forEach(item => {
          arr1.push({
            key: item.id,
            ...item,
            time: `${item.startTime}-${item.endTime}`
          });
        });
        Object.assign(transferData.value, arr1);
        responsibleVisible.value = true;
      } else {
        _message.error(res.message);
      }
    };

    const responsibleConf = itemId => {
      Promise.all([getVerifierSetting(itemId)]).then(res => {
        // console.log('Promise.all',res)
        try {
          // respData.professionals = getJobData(res[0].professionals, 1, res[1])
          respData.rests = getJobData(res[0].rests);
          respData.finances = getJobData(res[0].finances);
          respData.itemId = res[0].itemId;
          respData.itemName = res[0].itemName;
          nextTick(() => {
            respVisible.value = true;
          });
        } catch (error) {
          console.log(error);
        }
      });
    };

    const getJobData = (arr, bool, arr1) => {
      let respData = [];
      arr.forEach(item => {
        respData.push([item.jobId, `${item.idCard}-${item.projectId}-${item.userName}`]);
      });
      return respData;
    }; //查询审批责任人信息


    const getVerifierSetting = id => {
      return new Promise((resolve, reject) => {
        const param = {
          type: 13,
          belongProject: Number(Cookies.get("belongProject")),
          itemId: id ? id : null
        };
        selectVerifierSetting(param).then(res => {
          resolve(res.data);
        }).catch(error => {
          console.log('查询审批责任人信息', error);
        });
      });
    }; //关闭责任人弹窗


    const closeRespVisible = val => {
      respVisible.value = val;
      handleConf();
    }; //审批责任人项目列表


    const responsibleVisible = ref(false);
    const projectByResponsible = ref([]);
    const transferData = ref([]); //取消权限转移

    const cancelTransfer = async id => {
      let res = await setTemporaryTransferState({
        id
      });

      if (res.code === 200) {
        _message.success(res.message);

        handleConf();
      } else {
        _message.error(res.message);
      }
    }; //新增权限转移


    const addTransferVisible = ref(false);

    const addTransfer = () => {
      addTransferVisible.value = true;
    };

    const formRefForModelTransfer = ref(false);
    const formStateForModelTransfer = reactive({
      sourceUid: null,
      targetUid: [],
      time: []
    });

    const handleCancelTransfer = () => {
      formRefForModelTransfer.value.resetFields();
      addTransferVisible.value = false;
    };

    const adTransferLoading = ref(false);

    const handleOkTransfer = () => {
      console.log(formStateForModelTransfer);
      formRefForModelTransfer.value.validate().then(async () => {
        adTransferLoading.value = true;

        try {
          let param = {
            sourceUid: formStateForModelTransfer.sourceUid,
            targetUid: formStateForModelTransfer.targetUid[1],
            startTime: formStateForModelTransfer.time[0],
            endTime: formStateForModelTransfer.time[1],
            modularType: 13,
            belongProject: Number(Cookies.get("belongProject"))
          };
          let res = await setTemporaryTransfer(param);

          if (res.code === 200) {
            _message.success(res.message);

            adTransferLoading.value = false;
            formRefForModelTransfer.value.resetFields();
            addTransferVisible.value = false;
            handleConf();
          } else {
            _message.error(res.message);

            adTransferLoading.value = false;
          }
        } catch (error) {
          console.log(error);
          adTransferLoading.value = false;
        }
      });
    };

    const transferOption = ref([]);

    const getTransferOption = async () => {
      let param = {
        type: 13,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApproachByModular(param);

      if (res.code === 200) {
        transferOption.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            transferOption.value.push({
              value: item.idCard,
              label: item.userName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const personnelData = ref([]);

    const getPersonnelData = async val => {
      let param = {
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectJobCascade(param);

      if (res.code === 200) {
        const jobProjectsAll = res.data.jobProjectsAll;
        personnelData.value = conversion(jobProjectsAll); // if(res.data && res.data.length) {
        //   res.data.forEach(item => {
        //     personnelData.value.push({
        //       value: item.idCard,
        //       label: item.userName,
        //     })
        //   })
        // }
      } else {
        _message.error(res.message);
      }
    };

    const conversion = val => {
      const arr = [];
      val.forEach(item => {
        let obj = {
          value: item.jobId,
          label: item.jobName,
          children: []
        };

        if (item.hyEmployees.length) {
          item.hyEmployees.forEach(ixt => {
            obj.children.push({
              value: ixt.uid,
              label: ixt.userName,
              projectId: ixt.belongProject
            });
          });
        }

        arr.push(obj);
      });
      return arr;
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    };

    const rules = {
      sourceUid: [{
        required: true,
        message: '原审批责任人不能为空',
        trigger: 'change'
      }],
      targetUid: [{
        required: true,
        message: '临时转移人不能为空',
        trigger: 'change',
        type: 'array'
      }],
      time: [{
        required: true,
        message: '转移期限不能为空',
        trigger: 'change',
        type: 'array'
      }]
    };

    const deleteConf = async itemId => {
      try {
        let param = {
          itemId,
          modularType: 13
        };
        let res = await deleteVerifierSetting(param);

        if (res.code == 200) {
          _message.success(res.message);

          handleConf();
        } else {
          _message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
      getTableData();
      getBelongContract();
    });
    return {
      formRef,
      formState,
      onSubmit,
      resetForm,
      paginationConfig,
      columns,
      tableData,
      loading,
      onPageChange,
      handleApply,
      applyVisible,
      closeApplyVisible,
      toView,
      applyDetailVisible,
      closeApplyDetailVisible,
      applyDetailData,
      isCompanyAdmin,
      updateDataValue,
      belongContractOptions,
      respVisible,
      respData,
      handleConf,
      responsibleConf,
      closeRespVisible,
      responsibleVisible,
      projectByResponsible,
      responsibleColumns,
      responsibleConf,
      transferColumns,
      transferData,
      cancelTransfer,
      addTransfer,
      addTransferVisible,
      formRefForModelTransfer,
      formStateForModelTransfer,
      handleCancelTransfer,
      handleOkTransfer,
      adTransferLoading,
      transferOption,
      getTransferOption,
      personnelData,
      getPersonnelData,
      disabledDate,
      rules,
      deleteConf
    };
  }

});