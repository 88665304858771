import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { ProfileOutlined, BarsOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import { selectBelongContract } from '@/api/officeApi';
import { selectDutyMember } from '@/api/workOrder';
import { updateLogisticsApply, selectLogisticsById, updateToLogisticsApproval } from '@/api/logistics';
import moment from 'moment';
import { selectApprovalItem } from '@/api/material';
import Cookies from "js-cookie";
const columns = [{
  title: '序号',
  dataIndex: 'serialNo',
  width: 60,
  slots: {
    customRender: 'serialNo'
  }
}, {
  title: '物品名称',
  dataIndex: 'goodsName',
  slots: {
    customRender: 'goodsName'
  },
  width: 280
}, {
  title: '数量',
  dataIndex: 'goodsNum',
  slots: {
    customRender: 'goodsNum'
  },
  width: 150
}, {
  title: '总重量',
  dataIndex: 'goodsTotalWeight',
  slots: {
    customRender: 'goodsTotalWeight'
  },
  width: 150
}, {
  title: '批次',
  dataIndex: 'goodsBatch',
  slots: {
    customRender: 'goodsBatch'
  },
  width: 220
}, {
  title: '操作',
  dataIndex: 'operation',
  width: 60,
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    ProfileOutlined,
    BarsOutlined
  },
  props: {
    applyDetailData: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const isDisabledForCl = computed(() => {
      if (props.applyDetailData.state === 0) {
        return true;
      } else if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 2) {
        return false;
      }
    });
    const formState = reactive({
      theme: "",
      itemId: undefined,
      packingWay: "",
      transportRequire: "",
      bourn: "",
      addressee: "",
      carrier: "",
      planTransportTime: undefined,
      belongContract: undefined,
      carriageContract: undefined,
      freightEstimate: undefined,
      freightCalcMethod: "",
      goodsBills: [{
        goodsName: undefined,
        goodsNum: undefined,
        goodsTotalWeight: undefined,
        goodsBatch: undefined
      }],
      // approvalProcedureDtos: [],
      approvalDtos: [],
      hyApproachApprovals: [],
      content: "" //审批意见

    });
    const {
      validateTime,
      checkName,
      money
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }],
      belongContract: [{
        required: true,
        message: '归属合同不能为空',
        // type: 'number',
        trigger: 'change'
      }],
      carriageContract: [{
        required: true,
        message: '承运合同不能为空',
        // type: 'number',
        trigger: 'change'
      }],
      packingWay: [{
        required: true,
        message: '包装方式不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      transportRequire: [{
        required: true,
        message: '运输要求不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      bourn: [{
        required: true,
        message: '目的地不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      freightCalcMethod: [{
        required: true,
        message: '运费结算方式不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      addressee: [{
        required: true,
        validator: checkName,
        trigger: 'blur'
      }],
      planTransportTime: [{
        required: true,
        message: '计划发运时间不能为空',
        trigger: 'change',
        validator: validateTime
      }],
      carrier: [// {
      //   required: true,
      //   message: '承运人不能为空',
      //   trigger: 'change',
      //   type: 'array',
      // },
      {
        required: true,
        validator: checkName,
        trigger: 'blur'
      }],
      freightEstimate: [{
        required: true,
        validator: money,
        trigger: 'blur'
      }],
      content: [{
        required: true,
        message: '审批意见不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }]
    };

    const getData = async key => {
      const res = await selectLogisticsById(key);

      try {
        Object.assign(formState, res.data); // formState.carrier = JSON.parse(formState.carrier)

        console.log(formState);
      } catch (error) {
        console.log('物流详情:', error);
      }
    };

    const onDelete = key => {
      if (formState.goodsBills.length == 1) {
        _message.error('发运物品清单不能为空');

        return;
      }

      formState.goodsBills.splice(key, 1);
    };

    const handleAdd = () => {
      const newData = {
        // serialNo:undefined,
        goodsName: undefined,
        goodsNum: undefined,
        goodsTotalWeight: undefined,
        goodsBatch: undefined
      };
      formState.goodsBills.push(newData);
    };

    const loading = ref(false); //修改提交

    const handleOk = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        const obj = {};
        Object.assign(obj, formState);
        obj.belongContract = obj.belongContract ? obj.belongContract : null;
        obj.carriageContract = obj.carriageContract ? obj.carriageContract : null; // obj.carrier = JSON.stringify(formState.carrier)

        console.log(obj);
        loading.value = true;
        let res = await updateLogisticsApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        loading.value = false;
        console.log('error', error);
      });
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    }; //归属合同查询


    const belongContractOptions = ref([]);

    const getBelongContract = async () => {
      let res = await selectBelongContract();

      if (res.code === 200) {
        belongContractOptions.value = [];
        if (res.data) res.data.forEach(item => {
          belongContractOptions.value.push({
            value: item.contractName,
            label: `${item.contractName}(${item.contractNo})`
          });
        });
      } else {
        _message.error(res.message);
      }
    }; //岗位及人员


    const options = ref([]);

    const getJobCascade = async () => {
      let res = await selectDutyMember();

      if (res.code === 200) {
        // console.log(res)
        options.value = res.data; // console.log(options.value)
      }
    }; //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 13,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //审批员审批相关


    const getCurrent = arr => {
      const newArr = arr.filter(item => item.status == 1);
      return newArr.length - 1;
    };

    const setRoleType = num => {
      switch (num) {
        case 0:
          return "申请人";

        case 1:
          return "审批责任人";

        case 2:
          return "库管员";

        case 3:
          return "其他审批人";

        case 4:
          return "财务审批人";
      }
    }; //设置步骤条样式


    const setStatus = val => {
      const isError = val.some(item => item.isReject === 1);

      if (isError) {
        return 'error';
      }
    }; //审批


    const handlePassOrReject = async num => {
      if (!formState.content) {
        _message.error('审批意见不能为空！');

        return;
      }

      const param = {
        applyId: formState.applyId,
        content: formState.content,
        state: num,
        belongProject: formState.belongProject
      };
      const res = await updateToLogisticsApproval(param);

      if (res.code == 200) {
        _message.success('审批成功！');

        context.emit('closeApplyVisible', {
          isClose: false,
          state: 2
        });
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getBelongContract();
      getJobCascade();
      getApprovalItem();
      getData(props.applyDetailData.key);
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      handleOk,
      loading,
      disabledDate,
      handleAdd,
      onDelete,
      validateTime,
      belongContractOptions,
      options,
      projectOptions,
      columns,
      isDisabledForCl,
      getCurrent,
      setRoleType,
      setStatus,
      handlePassOrReject
    };
  }

});